<template>
  <div class="om">
    <PageTitle title="OM TELEKOMGUIDEN.SE"/>

    <div class="wrapper">
      <div class="container">
        <div class="om-content">
          Telekomguiden är etablerat för att på ett operatörsneutralt sätt visa vilka alternativ som
          finns tillgängliga för konsumenter och företag som berörs av Telias övergång från
          kopparnät till mer framtidssäkra lösningar via till exempel fiber, mobilt och satellit.
          Telekomguiden drivs av grossistaffären inom Telia Company AB, men är operatörsneutral i
          sin vägledning.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/PageTitle'

export default {
  components: { PageTitle }
}
</script>

<style lang="scss">
.om {
  &-content {
    margin: 80px 0;
  }
}
</style>
